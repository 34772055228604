import React, { useEffect, useRef } from "react";

const Container = () => {
  const btn = useRef();
  useEffect(() => {
    (btn.current as any).click();
  }, []);

  return (
    <a
      style={{ display: "none" }}
      ref={btn}
      href={"https://www.notion.so/qus/Resume-ee00a5ae5b86436dbf918886e7e07edd"}
    ></a>
  );
};

export default Container;
